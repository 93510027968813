import React from 'react';
import './App.css';
import Registre from './Registre';
import { Card, Container, Row, Col } from 'react-bootstrap';
import { withRouter } from "react-router-dom";

export default withRouter(class extends React.Component {

    getMusics = registre => () => this.props.history.push('/' + registre);

    home = (
        <Container className="mt-3">
            <h1 className="text-center">Roi David</h1>
            <p className="text-center">
                Bienvenue ! Veuillez cliquer sur votre registre pour consulter les musiques qui concernent votre
                voix.
            </p>
            <Row className="mt-4">
                <Col lg={3} sm={6} className="mb-3">
                    <Card bg="primary" text="white" className="registre-card"
                          onClick={this.getMusics('sopranos')}>
                        <Card.Body>
                            <Card.Title className="text-center mb-0">Sopranos</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} sm={6} className="mb-3">
                    <Card bg="success" text="white" className="registre-card"
                          onClick={this.getMusics('altos')}>
                        <Card.Body>
                            <Card.Title className="text-center mb-0">Altos</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} sm={6} className="mb-3">
                    <Card bg="info" text="white" className="registre-card"
                          onClick={this.getMusics('tenors')}>
                        <Card.Body>
                            <Card.Title className="text-center mb-0">Ténors</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
                <Col lg={3} sm={6} className="mb-3">
                    <Card bg="secondary" text="white" className="registre-card"
                          onClick={this.getMusics('basses')}>
                        <Card.Body>
                            <Card.Title className="text-center mb-0">Basses</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
            <Row>
                <Col className="mb-3">
                    <Card bg="light" className="registre-card"
                          onClick={this.getMusics('all')}>
                        <Card.Body>
                            <Card.Title className="text-center mb-0">Toutes les voix</Card.Title>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );

    render() {
        return (this.props.location.pathname === '/sopranos') ? (<Registre route="sopranos" voice="Sopranos"/>):
               (this.props.location.pathname === '/altos') ? (<Registre route="altos" voice="Altos"/>):
               (this.props.location.pathname === '/tenors') ? (<Registre route="tenors" voice="Ténors"/>):
               (this.props.location.pathname === '/basses') ? (<Registre route="basses" voice="Basses"/>):
               (this.props.location.pathname === '/all') ? (<Registre route="all" voice="Tous"/>):this.home;
    }
});
