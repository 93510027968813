import React from 'react';
import { Container, Row, Col, Button, Accordion, Card } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { InlineIcon } from "@iconify/react";
import home from "@iconify/icons-mdi/arrow-left-bold";
import down from '@iconify/icons-mdi/arrow-down-bold';
import ReactAudioPlayer from 'react-audio-player';

const SONGS = {
    'base_endpoint': 'https://roi-david.samf.me',
    'all': [{ path: '/all/1_Introduction.mp3', name: '1 - Introduction' },
        { path: '/all/2_Cantique_du_berger.mp3', name: '2 - Cantique du berger' },
        { path: '/all/3_Loue_soit_le_Seigneur.mp3', name: '3 - Loué soit le Seigneur', allVoices: true },
        { path: '/all/4_Chant_de_victoire.mp3', name: '4 - Chant de victoire' },
        { path: '/all/5_Cortege.mp3', name: '5 - Cortège' },
        { path: '/all/6_Psaume_ne_crains_rien.mp3', name: '6 - Psaume - Ne crains rien' },
        { path: '/all/7_Psaume_ah_si_javais_des_ailes_de_colombe.mp3', name: '7 - Psaume - Ah, si j\'avais des ailes de colombe' },
        { path: '/all/8_Psaume_cantique_des_prophetes.mp3', name: '8 - Psaume - Cantique des prophètes' },
        { path: '/all/9_Psaume_pitie_de_moi_mon_Dieu.mp3', name: '9 - Psaume - Pitié de moi mon Dieu' },
        { path: '/all/10_Le_camp_de_Saul.mp3', name: '10 - Le camp de Saül' },
        { path: '/all/11_Leternel_est_ma_lumiere_infinie.mp3', name: '11 - L\'éternel est ma lumière infinie' },
        { path: '/all/12_Incantation_la_pythonisse.mp3', name: '12 - Incantation la pythonisse' },
        { path: '/all/13_Marche_des_philistins.mp3', name: '13 - Marche des philistins' },
        { path: '/all/14_Lamentations_de_Guilboa.mp3', name: '14 - Lamentations de Guilboa' },
        { path: '/all/15_Cantique_de_fete.mp3', name: '15 - Cantique de fête' },
        { path: '/all/16_La_danse_devant_larche.mp3', name: '16 - La danse devant l\'arche' },
        { path: '/all/17_Cantique_de_mon_coeur_jaillit_un_cantique.mp3', name: '17 - Cantique - De mon coeur jaillit un cantique', allVoices: true },
        { path: '/all/18_Chant_de_la_servante.mp3', name: '18 - Chant de la servante' },
        { path: '/all/19_Psaume_de_penitence.mp3', name: '19 - Psaume de pénitence' },
        { path: '/all/20_Je_fus_concu_dans_le_peche.mp3', name: '20 - Je fus conçu dans le péché' },
        { path: '/all/21_Psaume_je_leve_mes_regards_vers_la_montagne.mp3', name: '21 - Psaume - Je lève mes regards vers la montagne' },
        { path: '/all/22_La_chanson_dephraim.mp3', name: '22 - La chanson d\'Ephraïm' },
        { path: '/all/23_Marche_des_Hebreux.mp3', name: '23 - Marche des Hébreux' },
        { path: '/all/24_Psaume_je_taimerai_Seigneur_dun_amour_tendre.mp3', name: '24 - Psaume - Je t\'aimerai, Seigneur, d\'un amour tendre' },
        { path: '/all/25_Psaume_dans_cet_effroi.mp3', name: '25 - Psaume - Dans cet effroi' },
        { path: '/all/26_Couronnement_de_Salomon.mp3', name: '26 - Couronnement de Salomon' },
        { path: '/all/27_Mort_de_David.mp3', name: '27 - Mort de David' }],
    'sopranos': [{ path: '/all/3_Loue_soit_le_Seigneur.mp3', name: '3 - Loué soit le Seigneur', allVoices: true },
        { path: '/sopranos/1chant_de_victoire_soprano.mp3', name: '4 - Chant de victoire' },
        { path: '/sopranos/3eternel_est_ma_lumiere_infinie_soprano.mp3', name: '11 - L\'éternel est ma lumière infinie' },
        { path: '/sopranos/4lamentations_de_gilboa_soprano_1.mp3', name: '14 - Lamentations de Guilboa (soprano 1)' },
        { path: '/sopranos/4lamentations_de_gilboa_soprano_2.mp3', name: '14 - Lamentations de Guilboa (soprano 2)' },
        { path: '/sopranos/5cantique_de_fete_soprano_1.mp3', name: '15 - Cantique de fête (soprano 1)' },
        { path: '/sopranos/5cantique_de_fete_soprano_2.mp3', name: '15 - Cantique de fête (soprano 2)' },
        { path: '/sopranos/6danse_devant_larche_soprano.mp3', name: '16 - La danse devant l\'arche' },
        { path: '/all/17_Cantique_de_mon_coeur_jaillit_un_cantique.mp3', name: '17 - Cantique - De mon coeur jaillit un cantique', allVoices: true },
        { path: '/sopranos/7psaume_de_penitence_soprano_alto.mp3', name: '19 - Psaume de pénitence' },
        { path: '/sopranos/8je_fus_concu_dans_le_peche_soprano.mp3', name: '20 - Je fus conçu dans le péché' },
        { path: '/sopranos/9chanson_ephaim_soprano_alto.mp3', name: '22 - La chanson d\'Ephraïm' },
        { path: '/sopranos/10je_taimerai_seigneur_dun_amour_tendre_soprano.mp3', name: '24 - Psaume - Je t\'aimerai, Seigneur, d\'un amour tendre' },
        { path: '/sopranos/11dans_cet_effroi_soprano.mp3', name: '25 - Psaume - Dans cet effroi' },
        { path: '/sopranos/12mort_de_david_soprano.mp3', name: '27 - Mort de David' }],
    'altos': [{ path: '/all/3_Loue_soit_le_Seigneur.mp3', name: '3 - Loué soit le Seigneur', allVoices: true },
        { path: '/altos/1chant_de_victoire_alto.mp3', name: '4 - Chant de victoire' },
        { path: '/altos/3eternel_est_ma_lumiere_infinie_alto.mp3', name: '11 - L\'éternel est ma lumière infinie' },
        { path: '/altos/4lamentations_de_gilboa_alto_1.mp3', name: '14 - Lamentations de Guilboa (alto 1)' },
        { path: '/altos/4lamentations_de_gilboa_alto_2.mp3', name: '14 - Lamentations de Guilboa (alto 2)' },
        { path: '/altos/5cantique_de_fete_alto_1.mp3', name: '15 - Cantique de fête (alto 1)' },
        { path: '/altos/5cantique_de_fete_alto_2.mp3', name: '15 - Cantique de fête (alto 2)' },
        { path: '/altos/6danse_devant_larche_alto.mp3', name: '16 - La danse devant l\'arche' },
        { path: '/all/17_Cantique_de_mon_coeur_jaillit_un_cantique.mp3', name: '17 - Cantique - De mon coeur jaillit un cantique', allVoices: true },
        { path: '/altos/7psaume_de_penitence_soprano_alto.mp3', name: '19 - Psaume de pénitence' },
        { path: '/altos/8je_fus_concu_dans_le_peche_alto.mp3', name: '20 - Je fus conçu dans le péché' },
        { path: '/altos/9chanson_ephaim_soprano_alto_copy.mp3', name: '22 - La chanson d\'Ephraïm' },
        { path: '/altos/10je_taimerai_seigneur_dun_amour_tendre_alto.mp3', name: '24 - Psaume - Je t\'aimerai, Seigneur, d\'un amour tendre' },
        { path: '/altos/11dans_cet_effroi_alto.mp3', name: '25 - Psaume - Dans cet effroi' },
        { path: '/altos/12mort_de_david_alto.mp3', name: '27 - Mort de David' }],
    'tenors': [{ path: '/all/3_Loue_soit_le_Seigneur.mp3', name: '3 - Loué soit le Seigneur', allVoices: true },
        { path: '/tenors/1chant_de_victoire_tenor.mp3', name: '4 - Chant de victoire' },
        { path: '/tenors/3cantique_des_prophetes_tenor.mp3', name: '8 - Psaume - Cantique des prophètes' },
        { path: '/tenors/3eternel_est_ma_lumiere_infinie_tenor.mp3', name: '11 - L\'éternel est ma lumière infinie' },
        { path: '/tenors/6danse_devant_larche_tenor.mp3', name: '16 - La danse devant l\'arche' },
        { path: '/all/17_Cantique_de_mon_coeur_jaillit_un_cantique.mp3', name: '17 - Cantique - De mon coeur jaillit un cantique', allVoices: true },
        { path: '/tenors/7psaume_de_penitence_tenor_basse.mp3', name: '19 - Psaume de pénitence' },
        { path: '/tenors/8je_fus_concu_dans_le_peche_tenor.mp3', name: '20 - Je fus conçu dans le péché' },
        { path: '/tenors/10je_taimerai_seigneur_dun_amour_tendre_tenor.mp3', name: '24 - Psaume - Je t\'aimerai, Seigneur, d\'un amour tendre' },
        { path: '/tenors/11dans_cet_effroi_tenor.mp3', name: '25 - Psaume - Dans cet effroi' },
        { path: '/tenors/12mort_de_david_tenor.mp3', name: '27 - Mort de David' }],
    'basses': [{ path: '/all/3_Loue_soit_le_Seigneur.mp3', name: '3 - Loué soit le Seigneur', allVoices: true },
        { path: '/basses/1chant_de_victoire_basse.mp3', name: '4 - Chant de victoire' },
        { path: '/basses/3cantique_des_prophetes_basse.mp3', name: '8 - Psaume - Cantique des prophètes' },
        { path: '/basses/3eternel_est_ma_lumiere_infinie_basse.mp3', name: '11 - L\'éternel est ma lumière infinie' },
        { path: '/basses/6danse_devant_larche_basse.mp3', name: '16 - La danse devant l\'arche' },
        { path: '/all/17_Cantique_de_mon_coeur_jaillit_un_cantique.mp3', name: '17 - Cantique - De mon coeur jaillit un cantique', allVoices: true },
        { path: '/basses/7psaume_de_penitence_tenor_basse_copy.mp3', name: '19 - Psaume de pénitence' },
        { path: '/basses/8je_fus_concu_dans_le_peche_basse.mp3', name: '20 - Je fus conçu dans le péché' },
        { path: '/basses/10je_taimerai_seigneur_dun_amour_tendre_basse.mp3', name: '24 - Psaume - Je t\'aimerai, Seigneur, d\'un amour tendre' },
        { path: '/basses/11dans_cet_effroi_basse.mp3', name: '25 - Psaume - Dans cet effroi' },
        { path: '/basses/12mort_de_david_basse.mp3', name: '27 - Mort de David' }]
};

export default class extends React.Component {
    render() {
        return (
            <div style={{ marginTop: '-38px' }}>
                <Container className="mt-3">
                    <Link to="/">
                        <Button style={{ position: 'relative', top: '42px' }}>
                            <InlineIcon icon={home} />
                        </Button>
                    </Link>
                    <h1 className="text-center">{ this.props.voice }</h1>
                    { this.props.route !== 'all' ? (
                        this.props.route === 'sopranos' || this.props.route === 'altos' ? (
                            <p className="text-center">Musique pour { this.props.voice.toLowerCase() } 1 et
                                { ' ' + this.props.voice.toLowerCase() } 2</p>
                        ):(
                            <p className="text-center">Musique pour { this.props.voice.toLowerCase() }</p>
                        )
                    ):(
                        <p className="text-center">CD original de l'ensemble vocal de Lausanne</p>
                    )}
                    <Row>
                        <Col>
                            <Accordion className="mt-2 mb-4">
                                { SONGS[this.props.route].map((item, index) => (
                                    <Card className="song-card" key={'song_card_' + index}>
                                        <Accordion.Toggle as={Card.Header} eventKey={'ev_' + index}>
                                            <Row>
                                                <Col className="song-name">
                                                   { item.name }
                                                </Col>
                                                <Col xs="auto" sm="auto">
                                                    <a href={ SONGS.base_endpoint + item.path }
                                                       target="_blank" onClick={e => e.stopPropagation()}>
                                                        <Button variant="success" className="btn-sm btn-dl-song">
                                                            <InlineIcon icon={down} />
                                                        </Button>
                                                    </a>
                                                </Col>
                                            </Row>
                                        </Accordion.Toggle>
                                        <Accordion.Collapse eventKey={ 'ev_' + index }>
                                            <Card.Body className="p-0">
                                                <ReactAudioPlayer src={ SONGS.base_endpoint + item.path } controls
                                                                  style={{ width: '100%', marginTop: '5px' }}/>
                                            </Card.Body>
                                        </Accordion.Collapse>
                                    </Card>
                                )) }
                            </Accordion>
                        </Col>
                    </Row>
                </Container>
            </div>
        );
    }
}
